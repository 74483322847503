<template>
    <el-col>
        <section v-if="event" class="h-100 d-flex flex-column">
            <div class="picture-wrap">
                <div class="scale">
                    <div class="item" v-lazy:background-image="event.picture">
                        <div class="backdrop flex-in-middle">
                            <a class="inner" :href="event.url" target="_blank">
                                <i class="el-icon-video-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="caption flex-grow-1 overflow-hidden" v-if="$org.org === 'shufe'">
                <div class="h-100 position-relative">
                    <p class="yellow-line" :class="one?'':'two-line'">{{ one ? event.subject : event.subject }}</p>
                    <div class="time">{{ one ? event.event_time : event.event_time.slice(0, 16) }}
                        <p class="float-right">{{ event.category }}</p>
                    </div>
                    <p class="description mt-3">{{ one ? event.description : '' }}</p>
                    <div class="annotation">
                        <div class="sponsor">{{ one ? event.sponsor : event.sponsor }}</div>
                        <div><i class="el-icon-view"></i>
                            {{ event.watched | number }}
                            <template v-if="event.comments">
                                | <i class="el-icon-chat-round"></i> {{ event.comments | number }}
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="caption flex-grow-1 overflow-hidden" v-else>
                <div class="h-100 position-relative">
                    <p class="yellow-line">{{ one ? event.subject : event.category }}</p>
                    <div class="time">{{ one?event.event_time:event.event_time.substr(0,16) }}
                        <p class="float-right" v-if="one">{{ event.category }}</p>
                    </div>
                    <p class="description mt-3">{{ one ? event.description : event.subject }}</p>
                    <div class="annotation">
                        <div class="sponsor">{{ one ? event.sponsor:""}}</div>
                        <div><i class="el-icon-view"></i>
                            {{ event.watched | number }} |
                            <i class="el-icon-chat-round"></i>
                            {{ event.comments | number }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-else class="h-100">
            <div class="no-data flex-in-middle h-100">
                {{ $t("MESSAGE.NO-DATA") }}
            </div>
        </section>
    </el-col>
</template>

<script>
export default {
    name: "event-card",
    props: {
        event: {
            type: Object, default() {
                return null
            }
        },
        one: {
            type: Boolean, default() {
                return false;
            }
        }
    }
}
</script>

<style scoped>

</style>
