<template>
    <div style="height: 0;width: 0" v-if="!user.token && $org.httponly_cookie">
        <iframe :src="url" frameborder="0" style="width: 0;height: 0" ref="httponly_token"></iframe>
    </div>
</template>

<script>
import commonMixin from "../common/mixins/common-mixin";
export default {
    name: "get-httponly-token",
    mixins: [commonMixin],
    props: {
        url: {
            type: String,
            default() {
                return '/go/token';
            }
        }
    },
    mounted() {
        const vm = this;
        if (!this.user.token) {
            this.$refs.httponly_token.onload = function () {
                const text = vm.$refs.httponly_token.contentWindow.document.body.innerText;
                if (text) {
                    const token = JSON.parse(text);
                    if (token) {
                        if (token['LtpaToken']) {
                            vm.$api('User.TdsToken', {token: token['LtpaToken']}).then(res => {
                                if (res.data.success) {
                                    vm.setUserInfo(res.data.data);
                                }
                            })
                        }
                    }
                }
            }
        }
    }
}

</script>

<style scoped lang="scss">

</style>
