<template>
    <section >
        <div class="portal-carousel-box">
            <portal-show-reels :carousel_height="carousel_height">
            </portal-show-reels>
        </div>
        <div class="event-list">
            <div class="event-title bg-white">
                <div class="ctr d-flex align-items-center justify-content-between h-100">
                    <div class="event-header ">
                        {{ $t("TITLE.LIVE-EVENT") }}
                    </div>
                    <div class="more">
                        <router-link :to="{path:'/live'}"> {{ $t('TITLE.MORE') }}</router-link>
                    </div>
                    <div class="divider"></div>
                </div>
            </div>
            <section class="ctr pb-5">
                <el-row :gutter="20" type="flex" class="events-row-1" :style="{height: events_card_height+'px'}">
                    <event-card :event="events_first" style="width: 40%;" one class="h-100">
                    </event-card>
                    <el-col style="width: 40%" class="h-100">
                        <event-card v-for="item in events_four" :key="item.id" :event="item"
                                    class="four-item">
                        </event-card>
                    </el-col>
                    <el-col class="calendar" style="width: 20%" :style="{height: events_card_height+'px'}">
                        <portal-calendar style="flex:0" :dates="dates"></portal-calendar>
                        <div class="hot-events">
                            <div class="yellow-line flex-grow-0">
                                {{ $t("TITLE.HOT-EVENTS") }}
                            </div>
                            <div class="content" >
                                <ol>
                                    <li v-for="item in events_hot" :key="item.id">
                                        <div class="subject"><el-link :underline="false" :href="item.url">{{item.subject}}</el-link></div>
                                        <div class="time">{{item.event_time | str16}}</div>
                                        <div class="watched"><el-icon name="view"></el-icon> {{item.watched | number}}</div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </section>

            <div class="event-title">
                <div class="ctr d-flex align-items-center justify-content-between h-100">
                    <div class="event-header ">
                        {{ $t("TITLE.MORE-EVENTS") }}
                    </div>
                    <div class="more">
                        <router-link :to="{path:'/live'}"> {{ $t('TITLE.MORE') }}</router-link>
                    </div>
                    <div class="divider"></div>
                </div>
            </div>
            <section class="ctr pb-5">
                <el-row :gutter="30"  class="events-row-1" >
                    <el-col >
                        <event-card v-for="item in events_rest" :key="item.id" :event="item"
                                    style="width: 20%"
                                    :style="{height: (events_card_height / 2 - 10)+'px'}"
                                    class="others">
                        </event-card>
                    </el-col>
                </el-row>
            </section>
        </div>
        <get-httponly-token v-if="$org.httponly_cookie"></get-httponly-token>
    </section>

</template>
<script>


import "@/views/scss/portal.scss"
import CommonMixin from "@/common/mixins/common-mixin"
import EventCard from "@/components/event-card";
import PortalShowReels from "@/components/portal-show-reels";
import PortalCalendar from "@/components/portal-calendar";
import GetHttponlyToken from "../../components/get-httponly-token.vue";

export default {
    name: "portal",
    components: {GetHttponlyToken, PortalCalendar, PortalShowReels, EventCard},
    mixins: [CommonMixin],
    data() {
        return {
            carousel_height: 315,
            show_reels: null,
            events_first: null,
            events_four: null,
            events_rest: null,
            events_card_height: 590,
            events_hot:null,
            dates:[],
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize, false);
        this.handleResize();
        this.loadEvents();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize, false)
    },
    computed: {},
    methods: {
        handleResize() {
            if (document.body.clientWidth >= 1440) {
                this.carousel_height = 568.12
                this.events_card_height = 590
            } else if (document.body.clientWidth >= 1200) {
                this.carousel_height = 433
                this.events_card_height = 550

            } else {
                this.carousel_height = 315
                this.events_card_height = 487
            }
        },

        loadEvents() {
            this.$api("Admin.Portal.Events",{},true).then(res=>{
                this.events_first = res.data.data.first;
                this.events_four = res.data.data.four;
                this.events_rest = res.data.data.rest;
                this.events_hot = res.data.data.hot;
                this.dates = res.data.data.dates || [];
            });
        },
    }
}
</script>
